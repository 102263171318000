import React from "react";

import classNames from "classnames";

export type FormRowProps = React.HTMLAttributes<HTMLDivElement>;

export const FormRow = ({ children, className, ...rest }: FormRowProps) => {
  return (
    <div {...rest} className={classNames("mb-4 flex flex-col gap-4 md:flex-row md:flex-wrap", className)}>
      {children}
    </div>
  );
};

export const FormRowItem = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...rest} className={classNames("flex flex-1", className)}>
      {children}
    </div>
  );
};
