import { UserRound, Car, NotepadText, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { FormOption } from "src/components/forms";
import type { BaseFormProps } from "src/components/forms";
import { Input, TextArea, PhoneInput, FormRow, FormRowItem, Select, Submit } from "src/components/forms";
import {
  getOrderStatusOptions,
  vatOptions,
  serviceUnitOptions,
  productUnitOptions,
  discountOptions,
} from "src/components/forms/form-options";
import { Button, IconButton } from "src/components/ui-components/buttons";
import { Section } from "src/components/ui-components/layout-components";
import { SectionTitle, Label } from "src/components/ui-components/typography";
import type { Order, Organization, OrderItemType } from "src/lib/types";
import { roundMoney } from "src/lib/utils";
import { getOrderValidationSchema } from "src/schemas";

const getDefaultValues = (initialData: Order | undefined) => {
  return {
    status: initialData?.status || "accepted",
    description: initialData?.description || "",
    order_items: initialData?.order_items || [],
    car: {
      vin: initialData?.car.vin || "",
      gov_number: initialData?.car.gov_number || "",
    },
    client: {
      first_name: initialData?.client.first_name || "",
      last_name: initialData?.client.last_name || "",
      email: initialData?.client.email || "",
      phone: initialData?.client.phone || "",
    },
  };
};

export type OrderFormData = Omit<Order, "id">;
type OrderFormProps = BaseFormProps<OrderFormData, Order> & {
  organization: Organization;
};

export const OrderForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  organization,
  ...restSubmitParams
}: OrderFormProps) => {
  const { t } = useTranslation();
  const orderStatusOptions = getOrderStatusOptions({ t });

  const [productCategoryOptions, setProductCategoryOptions] = useState<FormOption<number>[]>([]);
  const [productOptions, setProductOptions] = useState<FormOption<number>[]>([]);

  const [serviceCategoryOptions, setServiceCategoryOptions] = useState<FormOption<number>[]>([]);
  const [serviceOptions, setServiceOptions] = useState<FormOption<number>[]>([]);
  const [defaultCategories, setDefaultCategories] = useState<{ product: number; service: number }>({
    product: 0,
    service: 0,
  });

  useEffect(() => {
    if (!organization) return;
    const { products, product_categories, services, service_categories } = organization;
    setProductCategoryOptions(
      product_categories.map((category) => ({ value: category.id, label: category.name }))
    );
    setProductOptions(products.map((product) => ({ value: product.id, label: product.name })));
    setServiceCategoryOptions(
      service_categories.map((category) => ({ value: category.id, label: category.name }))
    );
    setServiceOptions(services.map((service) => ({ value: service.id, label: service.name })));
    const defaultProductCategory = product_categories.find((cat) => cat.default) || product_categories[0];
    const defaultServiceCategory = service_categories.find((cat) => cat.default) || service_categories[0];
    setDefaultCategories({
      product: defaultProductCategory.id,
      service: defaultServiceCategory.id,
    });
  }, [organization]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<OrderFormData>({
    resolver: zodResolver(getOrderValidationSchema({ t })),
    defaultValues: getDefaultValues(initialData),
    mode: "onChange",
  });

  useEffect(() => {
    reset(getDefaultValues(initialData));
  }, [initialData]);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "order_items",
  });

  const addOrderItem = (type: OrderItemType) => {
    append({
      type: type,
      category_id: defaultCategories[type],
      item_id: 0,
      name: "",
      price: 0,
      unit: "",
      quantity: 1,
      markup_value: 1,
      discount: 1,
      net_price: 0,
      vat: 1.23,
      total_price: 0,
    });
  };

  const calculateOrderItemPrice = (index: number) => {
    const type = getValues(`order_items.${index}.type`) as OrderItemType;
    const quantity = getValues(`order_items.${index}.quantity`) ?? 0;
    const price = getValues(`order_items.${index}.price`) ?? 0;
    const markup = getValues(`order_items.${index}.markup_value`) ?? 1;
    const markupPercent = type === "product" ? (100 + markup) / 100 : 1;
    const discount = getValues(`order_items.${index}.discount`) ?? 1;
    const netPrice = quantity * price * markupPercent * discount;
    const vat = getValues(`order_items.${index}.vat`) as number;
    setValue(`order_items.${index}.net_price`, roundMoney(netPrice), { shouldValidate: true });
    setValue(`order_items.${index}.total_price`, roundMoney(netPrice * vat), { shouldValidate: true });
  };

  const orderItems = watch("order_items");

  const getSummary = () => {
    const total = orderItems.reduce(
      (acc, item) => ({
        netPrice: acc.netPrice + (item.net_price || 0),
        vat: acc.vat + (item.total_price || 0) - (item.net_price || 0),
        totalPrice: acc.totalPrice + (item.total_price || 0),
      }),
      { netPrice: 0, vat: 0, totalPrice: 0 }
    );
    return {
      netPrice: roundMoney(total.netPrice),
      vat: roundMoney(total.vat),
      totalPrice: roundMoney(total.totalPrice),
    };
  };
  // TODO: memoize the total info
  const summary = getSummary();

  const submit: SubmitHandler<OrderFormData> = (data) => onSubmit(data);

  if (!organization) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <SectionTitle className="flex items-center gap-2">
        <Car size={18} className="muted-text" />
        <span>{t("car_singular")}</span>
      </SectionTitle>
      <Section className="mb-4">
        <FormRow>
          <FormRowItem>
            <Controller
              name="car.vin"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value || ""}
                  required
                  type="text"
                  label={t("vin_code")}
                  placeholder="1HGCM82633A123456"
                  error={errors.car?.vin}
                />
              )}
            />
          </FormRowItem>
          <FormRowItem>
            <Controller
              name="car.gov_number"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value || ""}
                  required
                  type="text"
                  label={t("license_plate")}
                  placeholder="GWE8304S"
                  error={errors.car?.gov_number}
                />
              )}
            />
          </FormRowItem>
        </FormRow>
      </Section>
      <SectionTitle className="flex items-center gap-2">
        <UserRound size={18} className="muted-text" />
        <span>{t("user_singular")}</span>
      </SectionTitle>
      <Section className="mb-4">
        <FormRow>
          <FormRowItem>
            <Controller
              name="client.first_name"
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" label={t("first_name")} error={errors.client?.first_name} />
              )}
            />
          </FormRowItem>
          <FormRowItem>
            <Controller
              name="client.last_name"
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" label={t("last_name")} error={errors.client?.last_name} />
              )}
            />
          </FormRowItem>
        </FormRow>
        <FormRow>
          <FormRowItem>
            <Controller
              name="client.email"
              control={control}
              render={({ field }) => (
                <Input {...field} required type="email" label={t("email")} error={errors.client?.email} />
              )}
            />
          </FormRowItem>
          <FormRowItem>
            <PhoneInput
              placeholder="(000) 000-0000"
              id="phone"
              name="client.phone"
              label={t("phone_number")}
              required
              defaultCountry="PL"
              control={control}
              error={errors.client?.phone}
              onChange={(value) => {
                setValue("client.phone", value || "", { shouldValidate: true });
              }}
            />
          </FormRowItem>
        </FormRow>
      </Section>

      <SectionTitle className="flex items-center gap-2">
        <NotepadText size={18} className="muted-text" />
        <span>{t("order_singular")}</span>
      </SectionTitle>
      <Section className="mb-4">
        <FormRow>
          <FormRowItem>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("status")}
                  options={orderStatusOptions}
                  onChange={(option) => field.onChange(option.value)}
                  error={errors.status}
                />
              )}
            />
          </FormRowItem>
        </FormRow>
        <FormRow>
          <FormRowItem>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  value={field.value || ""}
                  label={t("description")}
                  error={errors.description}
                />
              )}
            />
          </FormRowItem>
        </FormRow>
        <div className="flex flex-col gap-2">
          <div className="default-border border-b py-2">
            <Label className="mb-4">Services/Products:</Label>
            {fields.map((orderItem, index) => {
              const isService = orderItem.type === "service";
              return (
                <div
                  key={orderItem.id}
                  className="mb-4 flex flex-col items-start gap-2 md:flex-row md:flex-wrap">
                  <div className="w-44">
                    <Controller
                      name={`order_items.${index}.category_id`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value || ""}
                          required
                          label={isService ? "Service category" : "Product category"}
                          error={errors.order_items?.[index]?.unit}
                          onChange={(opt) => field.onChange(opt.value)}
                          options={isService ? serviceCategoryOptions : productCategoryOptions}
                        />
                      )}
                    />
                  </div>
                  <div className="min-w-40 flex-1">
                    <Controller
                      name={`order_items.${index}.name`}
                      control={control}
                      render={({ field }) => {
                        const selectedOption = isService
                          ? serviceOptions.find((opt) => opt.label === field.value)
                          : productOptions.find((opt) => opt.label === field.value);
                        return (
                          <Select
                            {...field}
                            required
                            value={selectedOption?.value || ""}
                            label={t("name")}
                            error={errors.order_items?.[index]?.name}
                            options={isService ? serviceOptions : productOptions}
                            onChange={(opt) => {
                              if (!opt) return;
                              if (isService) {
                                const selectedService = organization.services.find((s) => s.id === opt.value);
                                if (selectedService) {
                                  const { price, unit, vat } = selectedService;
                                  update(index, {
                                    ...orderItem,
                                    price,
                                    unit,
                                    vat,
                                    net_price: roundMoney(orderItem.quantity * price),
                                    total_price: roundMoney(orderItem.quantity * price * vat),
                                    item_id: opt.value,
                                  });
                                }
                                field.onChange(opt?.label);
                              } else {
                                const selectedProduct = organization.products.find((p) => p.id === opt.value);
                                if (selectedProduct) {
                                  const { unit, price, markup_value, vat } = selectedProduct;
                                  update(index, {
                                    ...orderItem,
                                    item_id: opt.value,
                                    unit,
                                    price,
                                    markup_value,
                                    vat,
                                  });
                                  calculateOrderItemPrice(index);
                                  field.onChange(opt.label);
                                }
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="w-28">
                    <Controller
                      name={`order_items.${index}.unit`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value || ""}
                          required
                          label="Unit"
                          error={errors.order_items?.[index]?.unit}
                          onChange={(opt) => field.onChange(opt.value)}
                          options={isService ? serviceUnitOptions : productUnitOptions}
                        />
                      )}
                    />
                  </div>
                  <div className="w-28">
                    <Controller
                      name={`order_items.${index}.price`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          required
                          value={field.value || 0}
                          label={t("price")}
                          type="number"
                          inputPostfix="PLN"
                          step={1}
                          min={0}
                          error={errors.order_items?.[index]?.price}
                          onChange={(e) => {
                            field.onChange(parseFloat(e.target.value));
                            calculateOrderItemPrice(index);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-20">
                    <Controller
                      name={`order_items.${index}.quantity`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          step={isService ? 0.1 : 1}
                          min={isService ? 0 : 1}
                          required
                          value={field.value || ""}
                          label={t("quantity_short")}
                          error={errors.order_items?.[index]?.quantity}
                          onChange={(e) => {
                            field.onChange(parseFloat(e.target.value));
                            calculateOrderItemPrice(index);
                          }}
                        />
                      )}
                    />
                  </div>

                  {orderItem.type === "product" && (
                    <div className="w-28">
                      <Controller
                        name={`order_items.${index}.markup_value`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            required
                            value={field.value || 0}
                            label="Markup"
                            inputPostfix="%"
                            type="number"
                            step={1}
                            min={0}
                            error={errors.order_items?.[index]?.markup_value}
                            onChange={(e) => {
                              field.onChange(parseFloat(e.target.value));
                              calculateOrderItemPrice(index);
                            }}
                          />
                        )}
                      />
                    </div>
                  )}

                  <div className="w-28">
                    <Controller
                      name={`order_items.${index}.discount`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value || 1}
                          label="Discount"
                          error={errors.order_items?.[index]?.discount}
                          onChange={(opt) => {
                            field.onChange(parseFloat(opt.value));
                            calculateOrderItemPrice(index);
                          }}
                          options={discountOptions}
                        />
                      )}
                    />
                  </div>
                  <div className="w-28">
                    <Controller
                      name={`order_items.${index}.net_price`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={field.value || 0}
                          label="Net price"
                          inputPostfix="PLN"
                          type="number"
                          disabled
                          error={errors.order_items?.[index]?.net_price}
                        />
                      )}
                    />
                  </div>
                  <div className="w-28">
                    <Controller
                      name={`order_items.${index}.vat`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value || 1}
                          required
                          label="VAT"
                          error={errors.order_items?.[index]?.vat}
                          onChange={(opt) => {
                            field.onChange(parseFloat(opt.value));
                            calculateOrderItemPrice(index);
                          }}
                          options={vatOptions}
                        />
                      )}
                    />
                  </div>
                  <div className="w-32">
                    <Controller
                      name={`order_items.${index}.total_price`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          required
                          value={field.value || 0}
                          label="Total price"
                          inputPostfix="PLN"
                          error={errors.order_items?.[index]?.total_price}
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-start self-stretch">
                    <IconButton onClick={() => remove(index)} color="danger" className="mt-7">
                      <Trash />
                    </IconButton>
                  </div>
                </div>
              );
            })}
            <div className="flex items-center justify-end gap-3">
              <Button type="button" size="sm" onClick={() => addOrderItem("service")}>
                Add a service
              </Button>
              <Button type="button" size="sm" onClick={() => addOrderItem("product")}>
                Add a product
              </Button>
            </div>
          </div>

          <div className="mt-6 pt-4">
            <Label>Summary</Label>
            <div className="default-border border">
              <table className="divide-default-border min-w-full divide-y text-right text-sm">
                <thead className="divide-default-border muted-text divide-y">
                  <tr>
                    <th className="px-2 py-1"></th>
                    <th className="px-2 py-1">Net price</th>
                    <th className="px-2 py-1">VAT</th>
                    <th className="px-2 py-1">Total price</th>
                  </tr>
                </thead>
                <tbody className="divide-default-border divide-y">
                  <tr>
                    <td className="muted-text px-2 py-1">Total:</td>
                    <td className="px-2 py-1">{summary.netPrice}</td>
                    <td className="px-2 py-1">{summary.vat}</td>
                    <td className="px-2 py-1">{summary.totalPrice}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Section>

      <div className="flex justify-center">
        <Submit align="right" disabled={!isValid || disabled || !isDirty} {...restSubmitParams} />
      </div>
    </form>
  );
};

export default OrderForm;
