import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, FormRow, FormRowItem, Submit } from "src/components/forms/form-components";
import type { ProductCategory } from "src/lib/types";
import { getProductCategoryValidationSchema } from "src/schemas";

export type ProductCategoryFormData = Omit<ProductCategory, "id" | "default" | "readonly"> & {
  id: number | undefined;
};

const getDefaultValues = (initialData: ProductCategory | undefined): ProductCategoryFormData => {
  return {
    id: initialData?.id,
    name: initialData?.name || "",
    description: initialData?.description || "",
    markup_value: initialData?.markup_value ?? 20,
  };
};

export const ProductCategoryForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
  submitText = "Submit",
}: BaseFormProps<ProductCategoryFormData, ProductCategory>) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<ProductCategoryFormData>({
    resolver: zodResolver(getProductCategoryValidationSchema({ t })),
    defaultValues: getDefaultValues(initialData),
    mode: "onChange",
  });

  const isDefaultCategory = initialData?.default;
  const isReadonlyCategory = initialData?.readonly;

  useEffect(() => {
    reset(getDefaultValues(initialData));
  }, [initialData]);

  const submit: SubmitHandler<ProductCategoryFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <Input {...field} value={field.value || ""} required type="hidden" error={errors.id} />
        )}
      />
      <FormRow>
        <FormRowItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                disabled={isDefaultCategory || isReadonlyCategory}
                required
                type="text"
                label="Name"
                error={errors.name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                disabled={isDefaultCategory || isReadonlyCategory}
                type="text"
                label="Description"
                error={errors.description}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem className="w-1/3">
          <Controller
            name="markup_value"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                type="number"
                label="Markup Value"
                error={errors.markup_value}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText={submitText}
        />
      </div>
    </form>
  );
};

export default ProductCategoryForm;
