import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import type { FormOption } from "src/components/forms";
import { Input, Select, FormRow, FormRowItem, Submit } from "src/components/forms/form-components";
import { vatOptions, productUnitOptions } from "src/components/forms/form-options";
import type { Product, ProductCategory } from "src/lib/types";
import { getProductValidationSchema } from "src/schemas";

export type ProductFormData = Omit<Product, "id"> & {
  id: number | undefined;
};

const getDefaultValues = (initialData: Product | undefined, defaultCategoryId: number): ProductFormData => {
  return {
    id: initialData?.id,
    category_id: initialData?.category_id || defaultCategoryId,
    name: initialData?.name || "",
    price: initialData?.price ?? 0,
    unit: initialData?.unit || "pcs",
    vat: initialData?.vat ?? 1.23,
    markup_type: initialData?.markup_type || "own",
    markup_value: initialData?.markup_value ?? 0,
  };
};

export const ProductForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
  categories,
  submitText = "Submit",
}: BaseFormProps<ProductFormData, Product> & {
  categories: ProductCategory[];
}) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<ProductFormData>({
    resolver: zodResolver(getProductValidationSchema({ t })),
    defaultValues: getDefaultValues(initialData, 1),
    mode: "onChange",
  });

  const categoryId = watch("category_id");
  const markupType = watch("markup_type");
  const [categoryOptions, setCategoryOptions] = useState<FormOption<number>[]>([]);

  useEffect(() => {
    if (!Array.isArray(categories) || categories.length === 0) return;
    setCategoryOptions(categories.map((category) => ({ value: category.id, label: category.name })));
    const defCategory = categories.find((category) => category.default) || categories[0];
    reset(getDefaultValues(initialData, defCategory.id));
    if (!initialData?.id) {
      setValue("category_id", defCategory.id, { shouldValidate: true });
    }
  }, [categories, initialData]);

  const submit: SubmitHandler<ProductFormData> = (data) => onSubmit(data);

  if (!categories) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <Input {...field} value={field.value || ""} required type="hidden" error={errors.id} />
        )}
      />
      <FormRow>
        <FormRowItem>
          <Controller
            name="category_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ""}
                required
                label="Category"
                error={errors.category_id}
                onChange={(opt) => field.onChange(opt.value)}
                options={categoryOptions}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label="Name"
                error={errors.name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem className="w-1/3">
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value}
                required
                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                type="number"
                label="Price"
                error={errors.price}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem className="w-1/3">
          <Controller
            name="unit"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ""}
                required
                label="Unit"
                error={errors.unit}
                onChange={(opt) => field.onChange(opt.value)}
                options={productUnitOptions}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem className="w-1/3">
          <Controller
            name="vat"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ""}
                required
                label="VAT"
                error={errors.vat}
                onChange={(opt) => field.onChange(opt.value)}
                options={vatOptions}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem className="w-1/3">
          <Controller
            name="markup_type"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ""}
                required
                label="Markup Type"
                error={errors.markup_type}
                onChange={(opt) => {
                  const category = categories.find((c) => c.id === categoryId);
                  if (opt.value === "inherited") {
                    if (category) {
                      setValue("markup_value", category.markup_value, { shouldValidate: true });
                    }
                  } else {
                    setValue("markup_value", 0, { shouldValidate: true });
                  }
                  field.onChange(opt.value);
                }}
                options={[
                  { value: "own", label: "Own" },
                  { value: "inherited", label: "Inherited from category" },
                ]}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem className="w-1/3">
          <Controller
            name="markup_value"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                disabled={markupType === "inherited"}
                min={0}
                step={0.1}
                required
                value={field.value}
                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                label="Markup Value"
                error={errors.markup_value}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText={submitText}
        />
      </div>
    </form>
  );
};

export default ProductForm;
