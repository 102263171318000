import classNames from "classnames";

import { GlobalDropdown } from "src/components/ui-components/dropdowns/GlobalDropdown";
import type { GlobalDropdownProps } from "src/components/ui-components/dropdowns/GlobalDropdown";
import { Pagination } from "src/components/ui-components/pagination";
import type { PaginationProps } from "src/components/ui-components/pagination";

export type TableColumn = {
  key: string;
  content: string | React.ReactNode;
};

export type TableCell = {
  key: string;
  content: string | React.ReactNode;
  value?: string | number | boolean | null | undefined;
};

export type TableRow = {
  id: number | string;
  cells: TableCell[];
  actions?: GlobalDropdownProps["items"];
};

export type TableProps = {
  className?: string;
  loading: boolean;
  noActions?: boolean;
  cols: TableColumn[];
  rows: TableRow[];
  pagination?: PaginationProps;
  showIndex?: boolean;
};

const INDEX_CELL_SPACING = "px-2 py-4 sm:px-2 md:px-4";
const CELL_SPACING = "py-1 px-4 sm:px-6 md:px-8 lg:px-12";

export const Table = ({
  className,
  noActions = false,
  loading,
  cols,
  rows,
  pagination,
  showIndex = false,
}: TableProps) => {
  return (
    <div className={classNames("mt-6 flex flex-col", className)}>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="default-border overflow-hidden rounded-md border md:rounded-xl">
            <table className="divide-default-border min-w-full divide-y">
              <thead className="default-bg">
                <tr>
                  {showIndex && (
                    <th
                      key="index"
                      scope="col"
                      className={classNames(
                        INDEX_CELL_SPACING,
                        "muted-text py-5 text-left text-sm font-normal"
                      )}>
                      #
                    </th>
                  )}
                  {cols.map((col) => (
                    <th
                      key={col.key}
                      scope="col"
                      className={classNames(CELL_SPACING, "muted-text py-5 text-left text-sm font-normal")}>
                      {col.content}
                    </th>
                  ))}
                  {!noActions && (
                    <th scope="col">
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-default-border default-bg divide-y">
                {loading ? (
                  <>
                    {new Array(pagination?.pagy ? pagination.pagy.count : 10).fill("").map((_, i) => (
                      <tr key={i} className="animate-pulse bg-gray-50 dark:bg-gray-800">
                        {showIndex && (
                          <td
                            key="index"
                            className={classNames(
                              INDEX_CELL_SPACING,
                              "animate-pulse whitespace-nowrap text-sm font-medium"
                            )}>
                            <span className="opacity-0">loading</span>
                          </td>
                        )}
                        {cols.map((col) => (
                          <td
                            key={col.key}
                            className={classNames(
                              CELL_SPACING,
                              "animate-pulse whitespace-nowrap text-sm font-medium"
                            )}>
                            <span className="opacity-0">loading</span>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    {rows.map((row, rowIndex) => (
                      <tr key={row.id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                        {showIndex && (
                          <td
                            key="index"
                            className={classNames(
                              INDEX_CELL_SPACING,
                              "text-sm font-normal text-gray-800 dark:text-gray-200"
                            )}>
                            {rowIndex + 1}
                          </td>
                        )}
                        {row.cells.map((cell) => (
                          <td
                            key={cell.key}
                            className={classNames(
                              CELL_SPACING,
                              "text-sm font-normal text-gray-800 dark:text-gray-200"
                            )}>
                            {cell.content}
                          </td>
                        ))}
                        {row.actions?.length ? (
                          <td
                            key="actions"
                            className={classNames(
                              "w-12 px-2 py-1 text-sm font-normal text-gray-800 dark:text-gray-200"
                            )}>
                            <GlobalDropdown items={row.actions} className="ml-auto" />
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {pagination?.pagy && (
              <div className={classNames("default-bg default-border border-t", CELL_SPACING)}>
                <Pagination pagy={pagination.pagy} onPageChange={pagination.onPageChange} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
