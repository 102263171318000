import { z } from "zod";

import type { GetValidationSchemaParams } from "./types";

export const getServiceValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    id: z.number().optional(),
    name: z.string().min(3).max(255),
    price: z.number().min(0),
    unit: z.string(),
    vat: z.number().min(0),
    category_id: z.number().optional(),
  });
};

export const getServiceCategoryValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    id: z.number().optional(),
    name: z.string().min(1).max(255),
    description: z.string().min(3).max(255),
  });
};
