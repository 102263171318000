import { Trash, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";

import { ProductCategoryForm } from "src/components/forms";
import type { ProductCategoryFormData } from "src/components/forms";
import { BasePage } from "src/components/layout/page-templates";
import { Button } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import type { ProductCategory } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

const dialogId = "product-category-dialog";
const entity = "product_category";

export const OrganizationProductCategoriesPage = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getSessionOrganizationSelector);
  const navigate = useNavigate();
  const { updateOrganizationRelations } = useCoreApi();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ProductCategory[] | null>(null);
  const [page, setPage] = useState(1);
  const [itemToDelete, setItemToDelete] = useState<ProductCategory | null>(null);
  const [itemToEdit, setItemToEdit] = useState<ProductCategory | undefined>(undefined);

  useEffect(() => {
    if (!organization) return;
    loadItems();
  }, [page, organization]);

  useEffect(() => {
    if (itemToDelete) {
      if (window.confirm(t("confirmation_entity_delete", { entity: t(`${entity}_singular`) }))) {
        console.log("delete", itemToDelete);
      } else {
        setItemToDelete(null);
      }
    }
  }, [itemToDelete]);

  useEffect(() => {
    if (itemToEdit) {
      openDialogById(dialogId);
    }
  }, [itemToEdit]);

  const loadItems = () => {
    if (!organization) return;
    const products = organization.products;
    setCategories(
      organization.product_categories.map((category) => ({
        ...category,
        products_count: products.filter((product) => product.category_id === category.id).length || 0,
      }))
    );
  };

  const onFormSubmit = (category: ProductCategoryFormData) => {
    if (!organization) return;
    const updatedCats =
      typeof category.id === "number"
        ? organization.product_categories.map((c) => (c.id === category.id ? { ...c, ...category } : c))
        : [
            ...organization.product_categories,
            { ...category, id: category.id ?? Math.floor(Math.random() * 1000) },
          ];
    updateOrganizationRelations({
      product_categories: updatedCats as ProductCategory[],
    }).then(() => {
      closeDialogById(dialogId);
      // loadItems();
    });
  };

  return (
    <BasePage
      title="Product Categories"
      description="Manage your categories of products"
      backLink=""
      actions={
        <Button
          onClick={() => {
            setItemToEdit(undefined);
            openDialogById(dialogId);
          }}>
          {t("cta_add")}
        </Button>
      }>
      <Table
        showIndex
        loading={loading && categories === null}
        cols={[
          { key: "name", content: "Name" },
          { key: "description", content: "Description" },
          { key: "markup", content: "Markup" },
          { key: "products", content: "Products" },
          { key: "type", content: <p className="text-center">Type</p> },
        ]}
        rows={
          categories
            ? categories.map((category) => ({
                id: category.id,
                cells: [
                  { key: "name", content: category.name },
                  { key: "description", content: category.description },
                  { key: "markup", content: category.markup_value + "%" },
                  {
                    key: "products",
                    content: category.products_count ? (
                      <Link to={`/organization/products?category=${category.id}`} className="link-colors">
                        {category.products_count}
                      </Link>
                    ) : (
                      0
                    ),
                  },
                  {
                    key: "type",
                    content: (
                      <div className="flex flex-wrap justify-center gap-1">
                        {category.default && (
                          <span className="muted-text rounded-md border px-1 text-xs">default</span>
                        )}
                        {category.readonly && (
                          <span className="muted-text rounded-md border px-1 text-xs">readonly</span>
                        )}
                        {!category.default && !category.readonly && (
                          <span className="muted-text rounded-md border px-1 text-xs">custom</span>
                        )}
                      </div>
                    ),
                  },
                ],
                actions: [
                  {
                    type: "button",
                    title: t("cta_edit"),
                    icon: <Pencil />,
                    action: () => setItemToEdit(category),
                  },
                  {
                    type: "button",
                    actionType: "danger",
                    disabled: category.default || category.readonly,
                    title: t("cta_delete"),
                    icon: <Trash />,
                    action: () => {
                      if (category.default || category.readonly) {
                        return;
                      }
                      if (category.products_count && category.products_count > 0) {
                        alert("Category has products, please delete or move them first");
                        return;
                      }
                      setItemToDelete(category);
                    },
                  },
                ],
              }))
            : []
        }
      />
      <Dialog
        id={dialogId}
        dialogTitle={t(itemToEdit ? "entity_update_title" : "entity_create_title", {
          entity: t(`${entity}_singular`),
        })}
        onClose={() => {
          setItemToDelete(null);
          setItemToEdit(undefined);
        }}>
        <Section className="w-[600px] max-w-full">
          <ProductCategoryForm
            loading={loading}
            disabled={loading}
            onSubmit={onFormSubmit}
            initialData={itemToEdit}
            submitText={t(itemToEdit ? "cta_save" : "cta_add")}
          />
        </Section>
      </Dialog>
    </BasePage>
  );
};

export default OrganizationProductCategoriesPage;
