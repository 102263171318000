import { Trash, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ServiceForm } from "src/components/forms";
import type { ServiceFormData } from "src/components/forms";
import { BasePage } from "src/components/layout/page-templates";
import { Button } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import type { Service, ServiceCategory } from "src/lib/types";
import { displayVAT } from "src/lib/utils";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

const dialogId = "service-form-dialog";
const entity = "service";

type ServiceWithCategory = Service & {
  category?: ServiceCategory;
};

export const OrganizationServicesPage = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getSessionOrganizationSelector);
  const { updateOrganizationRelations } = useCoreApi();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState<ServiceWithCategory[] | null>(null);
  const [categories, setCategories] = useState<ServiceCategory[] | null>(null);
  const [page, setPage] = useState(1);
  const [itemToDelete, setItemToDelete] = useState<ServiceWithCategory | null>(null);
  const [itemToEdit, setItemToEdit] = useState<ServiceWithCategory | undefined>(undefined);

  const loadItems = () => {
    if (!organization) return;
    const services = organization.services;
    const categories = organization.service_categories;
    const servicesWithCategory = services.map((item) => {
      const category = categories.find((category) => category.id === item.category_id);
      return { ...item, category: category };
    });
    setServices(servicesWithCategory);
    setCategories(categories);
  };

  const onFormSubmit = (service: ServiceFormData) => {
    if (!organization) return;
    const updatedServices =
      typeof service.id === "number"
        ? organization.services.map((s) => (s.id === service.id ? { ...s, ...service } : s))
        : [...organization.services, { ...service, id: service.id ?? Math.floor(Math.random() * 1000) }];
    updateOrganizationRelations({
      services: updatedServices as Service[],
    }).then(() => {
      closeDialogById(dialogId);
      // loadItems();
    });
  };

  useEffect(() => {
    if (!organization) return;
    loadItems();
  }, [page, organization]);

  useEffect(() => {
    if (itemToDelete) {
      if (window.confirm(t("confirmation_entity_delete", { entity: t(`${entity}_singular`) }))) {
        if (!organization) return;
        const updatedServices = organization.services.filter((service) => service.id !== itemToDelete.id);
        updateOrganizationRelations({ services: updatedServices }).then(() => {
          // loadItems();
        });
      } else {
        setItemToDelete(null);
      }
    }
  }, [itemToDelete]);

  useEffect(() => {
    if (itemToEdit) {
      openDialogById(dialogId);
    }
  }, [itemToEdit]);

  return (
    <BasePage
      title="Services"
      description="Manage your services list"
      backLink=""
      actions={
        <Button
          onClick={() => {
            setItemToEdit(undefined);
            openDialogById(dialogId);
          }}>
          {t("cta_add")}
        </Button>
      }>
      <Table
        showIndex
        loading={loading && services === null}
        cols={[
          { key: "name", content: "Name" },
          { key: "unit", content: "Unit" },
          { key: "price", content: "Price" },
          { key: "vat", content: "VAT" },
          { key: "category", content: "Category" },
        ]}
        rows={
          services
            ? services.map((service) => ({
                id: service.id,
                cells: [
                  {
                    key: "name",
                    content: service.name,
                  },
                  {
                    key: "unit",
                    content: service.unit,
                  },
                  {
                    key: "price",
                    content: service.price,
                  },
                  {
                    key: "vat",
                    content: displayVAT(service.vat),
                  },
                  {
                    key: "category",
                    content: (
                      <Link
                        to={`/organization/services-categories?search=${service.category_id}`}
                        className="link-colors">
                        {service.category?.name}
                      </Link>
                    ),
                  },
                ],
                actions: [
                  {
                    type: "button",
                    title: t("cta_edit"),
                    icon: <Pencil />,
                    action: () => setItemToEdit(service),
                  },
                  {
                    type: "button",
                    actionType: "danger",
                    title: t("cta_delete"),
                    icon: <Trash />,
                    action: () => setItemToDelete(service),
                  },
                ],
              }))
            : []
        }
      />
      <Dialog
        id={dialogId}
        dialogTitle={t(itemToEdit ? "entity_update_title" : "entity_create_title", {
          entity: t(`${entity}_singular`),
        })}
        onClose={() => {
          setItemToDelete(null);
          setItemToEdit(undefined);
        }}>
        <Section className="w-[600px] max-w-full">
          <ServiceForm
            loading={loading}
            disabled={loading}
            onSubmit={onFormSubmit}
            initialData={itemToEdit}
            categories={categories || []}
            submitText={t(itemToEdit ? "cta_save" : "cta_add")}
          />
        </Section>
      </Dialog>
    </BasePage>
  );
};

export default OrganizationServicesPage;
