import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OrderForm } from "../../../forms/order";
import type { OrderFormData } from "../../../forms/order";
import { BasePage } from "src/components/layout/page-templates";
import { useCoreApi } from "src/hooks";
import type { Order } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

type CreateOrderPayload = {
  order: OrderFormData;
};

export const OrganizationOrderCreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createOrgEntity } = useCoreApi();
  const organization = useAppSelector(getSessionOrganizationSelector);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: OrderFormData) => {
    setLoading(true);
    createOrgEntity<CreateOrderPayload, Order>({
      entity: "order",
      requestPayload: {
        order: data,
      },
    })
      .then((order) => {
        navigate(`/organization/orders/${order.id}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BasePage
      title={t("entity_create_title", { entity: t("order_singular") })}
      description={t("create_new_entity_description")}
      backLink="">
      {organization && (
        <OrderForm
          organization={organization}
          loading={loading}
          disabled={loading}
          onSubmit={onSubmit}
          submitText={t("cta_create")}
        />
      )}
    </BasePage>
  );
};

export default OrganizationOrderCreatePage;
