import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, FormRow, FormRowItem, Submit } from "src/components/forms/form-components";
import type { ServiceCategory } from "src/lib/types";
import { getServiceCategoryValidationSchema } from "src/schemas";

export type ServiceCategoryFormData = Omit<ServiceCategory, "id" | "default" | "readonly"> & {
  id: number | undefined;
};

const getDefaultValues = (initialData: ServiceCategory | undefined): ServiceCategoryFormData => {
  return {
    id: initialData?.id,
    name: initialData?.name || "",
    description: initialData?.description || "",
  };
};

export const ServiceCategoryForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
  submitText = "Submit",
}: BaseFormProps<ServiceCategoryFormData, ServiceCategory>) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<ServiceCategoryFormData>({
    resolver: zodResolver(getServiceCategoryValidationSchema({ t })),
    defaultValues: getDefaultValues(initialData),
    mode: "onChange",
  });

  const isDefaultCategory = initialData?.default;
  const isReadonlyCategory = initialData?.readonly;

  useEffect(() => {
    reset(getDefaultValues(initialData));
  }, [initialData]);

  const submit: SubmitHandler<ServiceCategoryFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <Input {...field} value={field.value || ""} required type="hidden" error={errors.id} />
        )}
      />
      <FormRow>
        <FormRowItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                disabled={isDefaultCategory || isReadonlyCategory}
                required
                type="text"
                label="Name"
                error={errors.name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                disabled={isDefaultCategory || isReadonlyCategory}
                type="text"
                label="Description"
                error={errors.description}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText={submitText}
        />
      </div>
    </form>
  );
};

export default ServiceCategoryForm;
