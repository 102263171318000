import type { FormOption, GetFormOptionsParams } from "src/components/forms";
import type { OrganizationUserRole, OrderStatus, OrderItemType } from "src/lib/types";

export const getOrganizationUserRoleOptions = ({
  t,
}: GetFormOptionsParams): FormOption<OrganizationUserRole>[] => {
  return [
    { value: "owner", label: t("role_owner", "owner") },
    { value: "manager", label: t("role_manager", "manager") },
    { value: "user", label: t("role_user", "user") },
  ];
};

export const getOrderStatusOptions = ({ t }: GetFormOptionsParams): FormOption<OrderStatus>[] => {
  return [
    { value: "draft", label: t("order_status_draft") },
    { value: "accepted", label: t("order_status_accepted") },
    { value: "inspection", label: t("order_status_inspection") },
    { value: "repair_in_progress", label: t("order_status_repair_in_progress") },
    { value: "repair_completed", label: t("order_status_repair_completed") },
    { value: "awaiting_pickup", label: t("order_status_awaiting_pickup") },
    { value: "closed", label: t("order_status_closed") },
  ];
};

export const getOrderItemTypeOptions = ({ t }: GetFormOptionsParams): FormOption<OrderItemType>[] => {
  return [
    { value: "service", label: t("order_item_type_service") },
    { value: "product", label: t("order_item_type_part") },
  ];
};

export const serviceUnitOptions = [
  { value: "h", label: "hour" },
  { value: "pcs", label: "pcs" },
];

export const productUnitOptions = [
  { value: "pcs", label: "pcs" },
  { value: "l", label: "l" },
  { value: "kg", label: "kg" },
];

export const vatOptions = [
  { value: 1, label: "none" },
  { value: 1.23, label: "23%" },
];

export const discountOptions = [
  { value: 1, label: "0%" },
  { value: 0.95, label: "5%" },
  { value: 0.9, label: "10%" },
  { value: 0.85, label: "15%" },
  { value: 0.8, label: "20%" },
  { value: 0.75, label: "25%" },
  { value: 0.7, label: "30%" },
  { value: 0.65, label: "35%" },
  { value: 0.6, label: "40%" },
  { value: 0.55, label: "45%" },
  { value: 0.5, label: "50%" },
  { value: 0.45, label: "55%" },
  { value: 0.4, label: "60%" },
  { value: 0.35, label: "65%" },
  { value: 0.3, label: "70%" },
  { value: 0.25, label: "75%" },
  { value: 0.2, label: "80%" },
  { value: 0.15, label: "85%" },
  { value: 0.1, label: "90%" },
  { value: 0.05, label: "95%" },
];
