import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import type { FormOption } from "src/components/forms";
import { Input, Select, FormRow, FormRowItem, Submit } from "src/components/forms/form-components";
import { vatOptions, serviceUnitOptions } from "src/components/forms/form-options";
import type { Service, ServiceCategory } from "src/lib/types";
import { getServiceValidationSchema } from "src/schemas";

export type ServiceFormData = Omit<Service, "id"> & {
  id: number | undefined;
};

const getDefaultValues = (initialData: Service | undefined, defaultCategoryId: number): ServiceFormData => {
  return {
    id: initialData?.id,
    category_id: initialData?.category_id || defaultCategoryId,
    name: initialData?.name || "",
    price: initialData?.price ?? 0,
    unit: initialData?.unit || "h",
    vat: initialData?.vat ?? 1.23,
  };
};

export const ServiceForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
  categories,
  submitText = "Submit",
}: BaseFormProps<ServiceFormData, Service> & {
  categories: ServiceCategory[];
}) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<ServiceFormData>({
    resolver: zodResolver(getServiceValidationSchema({ t })),
    defaultValues: getDefaultValues(initialData, 1),
    mode: "onChange",
  });

  const [categoryOptions, setCategoryOptions] = useState<FormOption<number>[]>([]);

  useEffect(() => {
    if (!Array.isArray(categories) || categories.length === 0) return;
    setCategoryOptions(categories.map((category) => ({ value: category.id, label: category.name })));
    const defCategory = categories.find((category) => category.default) || categories[0];
    reset(getDefaultValues(initialData, defCategory.id));
    if (!initialData?.id) {
      setValue("category_id", defCategory.id, { shouldValidate: true });
    }
  }, [categories, initialData]);

  const submit: SubmitHandler<ServiceFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <Input {...field} value={field.value || ""} required type="hidden" error={errors.id} />
        )}
      />
      <FormRow>
        <FormRowItem>
          <Controller
            name="category_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ""}
                required
                label="Category"
                error={errors.category_id}
                onChange={(opt) => field.onChange(opt.value)}
                options={categoryOptions}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label="Name"
                error={errors.name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem className="w-1/3">
          <Controller
            name="unit"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ""}
                required
                label="Unit"
                error={errors.unit}
                onChange={(opt) => field.onChange(opt.value)}
                options={serviceUnitOptions}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem className="w-1/3">
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value}
                required
                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                type="number"
                label="Price"
                error={errors.price}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem className="w-1/3">
          <Controller
            name="vat"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ""}
                required
                label="VAT"
                error={errors.vat}
                onChange={(opt) => field.onChange(opt.value)}
                options={vatOptions}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText={submitText}
        />
      </div>
    </form>
  );
};

export default ServiceForm;
