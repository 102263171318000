import { Trash, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ProductForm } from "src/components/forms";
import type { ProductFormData } from "src/components/forms";
import { BasePage } from "src/components/layout/page-templates";
import { Button } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import type { Product, ProductCategory } from "src/lib/types";
import { displayVAT } from "src/lib/utils";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

const dialogId = "product-form-dialog";
const entity = "product";

type ProductWithCategory = Product & {
  category?: ProductCategory;
};

export const OrganizationProductsPage = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getSessionOrganizationSelector);
  const { updateOrganizationRelations } = useCoreApi();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductWithCategory[] | null>(null);
  const [categories, setCategories] = useState<ProductCategory[] | null>(null);
  const [page, setPage] = useState(1);
  const [itemToDelete, setItemToDelete] = useState<ProductWithCategory | null>(null);
  const [itemToEdit, setItemToEdit] = useState<Product | undefined>(undefined);

  const loadItems = () => {
    if (!organization) return;
    const products = organization.products;
    const categories = organization.product_categories;
    const productsWithCategory = products.map((product) => {
      const category = categories.find((category) => category.id === product.category_id);
      return { ...product, category: category };
    });
    setProducts(productsWithCategory);
    setCategories(categories);
  };

  const onFormSubmit = (product: ProductFormData) => {
    if (!organization) return;
    const updatedProducts =
      typeof product.id === "number"
        ? organization.products.map((p) => (p.id === product.id ? { ...p, ...product } : p))
        : [...organization.products, { ...product, id: product.id ?? Math.floor(Math.random() * 1000) }];
    updateOrganizationRelations({
      products: updatedProducts as Product[],
    }).then(() => {
      closeDialogById(dialogId);
      // loadItems();
    });
  };

  useEffect(() => {
    if (!organization) return;
    loadItems();
  }, [page, organization]);

  useEffect(() => {
    if (itemToDelete) {
      if (window.confirm(t("confirmation_entity_delete", { entity: t(`${entity}_singular`) }))) {
        if (!organization) return;
        const updatedProducts = organization.products.filter((product) => product.id !== itemToDelete.id);
        updateOrganizationRelations({ products: updatedProducts }).then(() => {
          // loadItems();
        });
      } else {
        setItemToDelete(null);
      }
    }
  }, [itemToDelete]);

  useEffect(() => {
    if (itemToEdit) {
      openDialogById(dialogId);
    }
  }, [itemToEdit]);

  return (
    <BasePage
      title="Products"
      description="Manage your products list"
      backLink=""
      actions={
        <Button
          onClick={() => {
            setItemToEdit(undefined);
            openDialogById(dialogId);
          }}>
          {t("cta_add")}
        </Button>
      }>
      <Table
        showIndex
        loading={loading && products === null}
        cols={[
          { key: "name", content: "Name" },
          { key: "unit", content: "Unit" },
          { key: "price", content: "Price" },
          { key: "markup", content: "Markup" },
          { key: "vat", content: "VAT" },
          { key: "category", content: "Category" },
        ]}
        rows={
          products
            ? products.map((product) => ({
                id: product.id,
                cells: [
                  {
                    key: "name",
                    content: product.name,
                  },
                  {
                    key: "unit",
                    content: product.unit,
                  },
                  {
                    key: "price",
                    content: product.price,
                  },
                  {
                    key: "markup",
                    content: (
                      <div>
                        <span>
                          {product.markup_type === "own"
                            ? product.markup_value
                            : product.category?.markup_value}
                          %
                        </span>
                        {product.markup_type === "own" && (
                          <span className="muted-text ml-1 text-xs">(own)</span>
                        )}
                      </div>
                    ),
                  },
                  {
                    key: "vat",
                    content: displayVAT(product.vat),
                  },
                  {
                    key: "category",
                    content: (
                      <Link
                        to={`/organization/product-categories?search=${product.category_id}`}
                        className="link-colors">
                        {product.category?.name}
                      </Link>
                    ),
                  },
                ],
                actions: [
                  {
                    type: "button",
                    title: t("cta_edit"),
                    icon: <Pencil />,
                    action: () => setItemToEdit(product),
                  },
                  {
                    type: "button",
                    actionType: "danger",
                    title: t("cta_delete"),
                    icon: <Trash />,
                    action: () => setItemToDelete(product),
                  },
                ],
              }))
            : []
        }
      />
      <Dialog
        id={dialogId}
        dialogTitle={t(itemToEdit ? "entity_update_title" : "entity_create_title", {
          entity: t(`${entity}_singular`),
        })}
        onClose={() => {
          setItemToDelete(null);
          setItemToEdit(undefined);
        }}>
        <Section className="w-[600px] max-w-full">
          <ProductForm
            loading={loading}
            disabled={loading}
            onSubmit={onFormSubmit}
            initialData={itemToEdit}
            categories={categories || []}
            submitText={t(itemToEdit ? "cta_save" : "cta_add")}
          />
        </Section>
      </Dialog>
    </BasePage>
  );
};

export default OrganizationProductsPage;
