import type { Product, ProductCategory, Service, ServiceCategory } from "src/lib/types";

export const MOCK_PRODUCT_CATEGORIES: ProductCategory[] = [
  {
    id: 1,
    name: "Global",
    description: "This is a global category and will be attached to all products by default",
    markup_value: 20,
    default: true,
    readonly: true,
  },
  {
    id: 2,
    name: "Сar chemistry",
    description: "Car chemistry products, oils and liquids",
    markup_value: 18,
    default: false,
    readonly: true,
  },
  {
    id: 3,
    name: "Engine",
    description: "Engine parts and details",
    markup_value: 21,
    default: false,
    readonly: true,
  },
  {
    id: 4,
    name: "Transmission",
    description: "Transmission parts and details",
    markup_value: 22,
    default: false,
    readonly: true,
  },
  {
    id: 5,
    name: "Brakes",
    description: "Brake system parts and details",
    markup_value: 28,
    default: false,
    readonly: false,
  },
];

export const MOCK_PRODUCTS: Product[] = [
  {
    id: 1,
    name: "Engine oil Castrol 5w/40",
    price: 10,
    unit: "l",
    markup_type: "own",
    markup_value: 27,
    vat: 1.23,
    category_id: 1,
  },
  {
    id: 2,
    name: "Brake pads",
    price: 20,
    unit: "pcs",
    markup_type: "inherited",
    markup_value: 0,
    vat: 1.23,
    category_id: 4,
  },
  {
    id: 3,
    name: "Transmission oil",
    price: 15,
    unit: "l",
    markup_type: "inherited",
    markup_value: 0,
    vat: 1.23,
    category_id: 1,
  },
  {
    id: 4,
    name: "Engine piston",
    price: 50,
    unit: "pcs",
    markup_type: "inherited",
    markup_value: 0,
    vat: 1.23,
    category_id: 2,
  },
  {
    id: 5,
    name: "Brake disc",
    price: 30,
    unit: "pcs",
    markup_type: "inherited",
    markup_value: 0,
    vat: 1.23,
    category_id: 5,
  },
  {
    id: 6,
    name: "Transmission belt",
    price: 25,
    unit: "pcs",
    markup_type: "inherited",
    markup_value: 0,
    vat: 1.23,
    category_id: 3,
  },
  {
    id: 7,
    name: "Engine filter",
    price: 5,
    unit: "pcs",
    markup_type: "inherited",
    markup_value: 0,
    vat: 1.23,
    category_id: 2,
  },
];

export const MOCK_SERVICE_CATEGORIES: ServiceCategory[] = [
  {
    id: 1,
    name: "Global",
    description: "This is a global category and will be attached to all services by default",
    default: true,
    readonly: true,
  },
  {
    id: 2,
    name: "Engine",
    description: "Engine service and repair",
    default: false,
    readonly: true,
  },
  {
    id: 3,
    name: "Transmission",
    description: "Transmission service and repair",
    default: false,
    readonly: true,
  },
  {
    id: 4,
    name: "Brakes",
    description: "Brake system service and repair",
    default: false,
    readonly: false,
  },
];

export const MOCK_SERVICES: Service[] = [
  {
    id: 1,
    name: "Engine repair",
    price: 100,
    unit: "h",
    vat: 1.23,
    category_id: 1,
  },
  {
    id: 2,
    name: "Brake pads replacement",
    price: 50,
    unit: "pcs",
    vat: 1.23,
    category_id: 4,
  },
  {
    id: 3,
    name: "Transmission repair",
    price: 120,
    unit: "h",
    vat: 1.23,
    category_id: 1,
  },
];
